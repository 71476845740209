.title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 25%;
    top: 75%;
}

.icon {
    position: absolute;
    width: 16%;
    left: 45%;
    top: 65%;
    height: 10%;
}

.poster {
    width: 100%;
    height: 62.5%;
    object-fit: cover;
    position: absolute;
}

.wrapper {
    position: relative;
    padding-bottom: 160%;
    cursor: pointer;
}